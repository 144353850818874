<template>
    <div class="panoramicMaterial">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">全景素材</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="select-item">
                        <el-select filterable clearable v-model="type_id" 
                            placeholder="请选择分类筛选" @change="clickTypeChange">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right" >
                    <el-button @click="batchesDelete" type="danger" plain>批量删除</el-button>
                    <el-button @click="uploadMaterial" type="primary">上传素材</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column align="center" prop="productFileId" label="素材图片" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <img :src="scope.row.productFileId" height="50" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="productNum" label="型号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="model" label="规格" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div>{{scope.row.productWidth}}*{{scope.row.productHeight}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="type" label="分类" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="140">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'panoramicMaterial',
            typeOptions: [],
            type_id: '',
            keyword: '',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            tableData: [],
            idsList: [],
        }
    },
    mounted () {
        this.getSceneList();
        this.getSucaiType();
    },
    methods: {
        // 多选批量
        handleSelectionChange(val){
            this.idsList = val;
        },
        // 批量删除
        batchesDelete(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要删除的素材')
            }else{
                this.$confirm('是否确定批量删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for (let i = 0; i < this.idsList.length; i++) {
                        common.connect('/distributorpcv1/Scene/get_sucai_del', {id: this.idsList[i].productId}, ()=>{
                            this.$message({
                                showClose: true,
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getSceneList()
                        })
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        // 获取数据列表
        getSceneList(){
            let params = {
                page: this.currentPage,
                page_size: this.pageSize,
                type: this.type_id,
                like: this.keyword
            }
            this.loading = true;
            common.connect('/distributorpcv1/Scene/get_sucai_list',params,(res)=>{
                if (res.data) {
                    this.total = res.data.total;
                    this.tableData = res.data.data;
                } else {
                    this.total = 0
                    this.tableData = []
                }
                this.loading = false;
            })
        },
        // 获取类型数据
        getSucaiType(){
            common.connect('/distributorpcv1/Scene/list_sucai_kind', {}, res=>{
                this.typeOptions = res.data
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('是否确定删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/Scene/get_sucai_del', {id: row.productId}, ()=>{
                    this.$message({
                        showClose: true,
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getSceneList()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 编辑
        clickEdit(row){
            this.$router.push({path:'/PanoramaManagement3D/changedPanoramaMaterial', query: {sucaiid: row.productId}})
        },
        // 上传素材
        uploadMaterial(){
            this.$router.push("./uploadMaterial")
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getSceneList();
        },
        // 分类筛选
        clickTypeChange(){
            this.currentPage = 1;
            this.getSceneList();
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getSceneList();
        }
    },
}
</script>

<style lang='scss'>
.panoramicMaterial{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .select-item{
                    margin-right: 15px;
                    margin-bottom: 10px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 10px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
}
</style>
